
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { getBusinessList } from '@/api/business';
import Pagination from '@/components/board/pagination.vue';
import { getBoardCategory } from '@/api/boardCategory';
import { UserModule } from '@/store/user';

@Component({
  name: 'BusinessIndex',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Watch('selectedCategoryList')
  private handleChangeCategoryList() {
    if (this.selectedCategoryList.length === this.category.children.length) {
      this.allCheck = true;
    } else {
      this.allCheck = false;
    }
  }

  @Watch('selectedCategoryList2')
  private handleChangeCategoryList2() {
    if (this.selectedCategoryList2.length === this.category2.children.length) {
      this.allCheck2 = true;
    } else {
      this.allCheck2 = false;
    }
  }

  @Watch('selectedCategoryList3')
  private handleChangeCategoryList3() {
    if (this.selectedCategoryList3.length === this.category3.children.length) {
      this.allCheck3 = true;
    } else {
      this.allCheck3 = false;
    }
  }

  mounted() {
    this.init();
  }

  get isBusinessAdmin() {
    return UserModule.roles.indexOf('ROLE_ADMIN') > -1 || UserModule.roles.indexOf('ROLE_b2ba2135-770c-461f-8939-932a7b94dff8') > -1;
  }

  private listQuery: any = {
    page: 0,
    size: 10,
    searchValue: '',
    sort: 'progress',
    progress: 0,
    categories: [],
    target: '',
    startDate: '',
    endDate: '',
  };

  private businessList = [];

  private selectedCategoryList: any = [];

  private selectedCategoryList2: any = [];

  private selectedCategoryList3: any = [];

  private category: any = {
    categoryName: '',
    children: [],
  };

  private category2: any = {
    categoryName: '',
    children: [],
  };

  private category3: any = {
    categoryName: '',
    children: [],
  };

  private allCheck = true;

  private allCheck2 = false;

  private allCheck3 = false;

  private totalElements = 0;

  private totalPages = 1;

  private async init() {
    getBoardCategory('33b34a3c-b79c-43bd-8042-e364d0f560da').then((res) => {
      const filteredChildren = res.data.children.filter((child: any) => child.categoryName !== '금융');
      this.category = {
        ...res.data,
        children: filteredChildren,
      };
      res.data.children.forEach((child: any) => this.selectedCategoryList.push(child.uid));
    }).then(() => {
      getBoardCategory('135f0245-97e2-4210-8317-08f01e0cf219').then((res) => {
        this.category2 = res.data;
        this.selectedCategoryList2.push('40d51458-44e6-4f3b-91db-732941ac2571');
        this.getBusinessList();
      });
    }).then(() => {
      getBoardCategory('48d257cf-f358-4f2d-920a-404804aa0abb').then((res) => {
        this.category3 = res.data;
        this.selectedCategoryList3.push('48d257cf-f358-4f2d-920a-404804aa0abb');
        this.getBusinessList();
      });
    });
  }

  getBusinessList() {
    if (this.selectedCategoryList.length > 0) {
      this.listQuery.categories = this.selectedCategoryList.join(',');
    } else {
      this.listQuery.categories = '';
    }

    if (this.selectedCategoryList2.length > 0) {
      this.listQuery.categories2 = this.selectedCategoryList2.join(',');
    }
    if (this.allCheck2) this.listQuery.categories2 = '';

    if (this.selectedCategoryList3.length > 0) {
      this.listQuery.target = this.selectedCategoryList3.join(',');
    }
    if (this.allCheck3) this.listQuery.target = '';

    getBusinessList(this.listQuery).then((res: any) => {
      this.businessList = res.data.content;
      this.totalElements = res.data.totalElements;
      this.totalPages = res.data.totalPages;
    });
  }

  private isEnd(dueDate: number) {
    if (dueDate >= 1) return `마감 ${dueDate} 일전`;
    if (dueDate === 0) return '오늘 마감';
    return '마감';
  }

  private handleClickProgress(value: number) {
    this.listQuery.progress = value;
    this.listQuery.page = 0;
    this.getBusinessList();
  }

  private handleChangeAllCheck(e: any) {
    if (e.target.checked) {
      const categoryUidList = this.category.children.map((c: any) => c.uid);
      this.selectedCategoryList = categoryUidList;
    } else {
      this.selectedCategoryList = [];
    }
  }

  private handleChangeAllCheck2(e: any) {
    if (e.target.checked) {
      const categoryUidList2 = this.category2.children.map((c: any) => c.uid);
      this.selectedCategoryList2 = categoryUidList2;
    } else {
      this.selectedCategoryList2 = [];
    }
  }

  private handleChangeAllCheck3(e: any) {
    if (e.target.checked) {
      const categoryUidList3 = this.category3.children.map((c: any) => c.uid);
      this.selectedCategoryList3 = categoryUidList3;
    } else {
      this.selectedCategoryList3 = [];
    }
  }

  private handleSearch() {
    this.listQuery.page = 0;
    this.getBusinessList();
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.getBusinessList();
  }

  private getNumber(index: number) {
    let totalSize = (this.listQuery.page - 1) * this.listQuery.size;
    if (totalSize < 0) totalSize = 0;
    return this.totalElements - totalSize - index;
  }

  private getProgessClass(business: any) {
    if (business.dueDate >= 30) return 'state03';
    if (business.dueDate >= 7) return 'state02';
    if (business.dueDate >= 0) return 'state01';
    return 'state00';
  }

  private searchReset() {
    this.listQuery.searchValue = '';
    this.allCheck = false;
    this.allCheck2 = false;
    this.selectedCategoryList = false;
    this.selectedCategoryList2 = false;
  }
}
